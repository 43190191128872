

































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {validationMixin} from 'vuelidate';
import {email, numeric, required} from 'vuelidate/lib/validators';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import {Permission} from '@/misc/enums/permission.enum';

const UserStore = namespace('user');
const AuthStore = namespace('auth');

@Component({
  components: {RJTextField},
  mixins: [validationMixin],
  validations: {
    userCopy: {
      email: {
        required,
        email,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      phone: {
        required,
        numeric,
      },
    },
  },
})
export default class UserProfileComponent extends mixins(ErrorMessageHandlerMixin) {

  @Prop({default: () => new User()})
  public user!: User;

  @UserStore.Action('editUserAction')
  private editUserAction!: (user: User) => any;
  @AuthStore.Mutation('saveUser')
  private saveUser!: (user: User) => void;

  public userCopy: User = new User();
  public showComponent: boolean = false;
  public inputDisabled: boolean = false;


  @Watch('user', {immediate: true})
  public onUserChange() {
    this.userCopy = User.parseFromObject(this.user);
    this.showComponent = true;
    this.$v.userCopy!.$touch();
    // The admin can't change his profile!
    this.inputDisabled = !this.$userRoleHandler.hasPermission(Permission.USER_READ) ||
        this.userCopy.role!.isSuperAdmin;
  }


  /**
   * Event handler that saves the edition or the creation of user object
   */
  public async onSubmit(event: Event) {
    event.preventDefault();
    this.$v.userCopy!.$touch();

    if (this.$v.userCopy!.$invalid) {
      // if invalid scroll to first error input
      // OPTIMIZE the use of an css selector of a vuetify property is not always safe
      requestAnimationFrame(() => this.$vuetify.goTo('.error--text', {offset: 50}));
    } else {
      try {
        await this.editUserAction(this.userCopy);
        this.saveUser(this.userCopy); // Save the user to the local storage
        this.$emit('exitModal', this.userCopy);
        this.$notifySuccessSimplified('USER.NOTIFICATIONS.USER_EDIT.SUCCESS');
      } catch (e: any) {
        this.$notifyErrorSimplified('USER.NOTIFICATIONS.USER_EDIT.ERROR');
      }
    }
  }
}
